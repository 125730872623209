import React, {ReactNode} from "react";
import styled, { ThemeProvider } from "styled-components";
import { themeList, GlobalStyles } from "../../styles";
import {media} from "../../styles/theme";
import { Link } from "gatsby";


class Layout extends React.Component<{uri:string, children:ReactNode, navContent?:ReactNode}>
{
  state = {};



  componentDidMount()
  {
    
  }

  componentWillUnmount()
  {

  }


  render()
  {
    
    //return <div>{this.props.children}</div>
    return (
       <ThemeProvider theme={themeList[0]}>
            <GlobalStyles />
            <Header>
              <Logo src="/image/logo.png" /><HeaderTitle>in Wonrld...</HeaderTitle>
              <HeaderBackground>
              </HeaderBackground>
            </Header>
            {<Nav>
              <NavButton selected={this.props.uri == "/"} to="/">Home</NavButton>
              <NavButton selected={this.props.uri?.includes("/adventures")} to="/adventures">Adventures</NavButton>
              <NavButton selected={this.props.uri?.includes("/aboutme")} to="/aboutme">About me</NavButton>
            </Nav>}
            
            <Container>
              <ContentRow>
                {this.props.children}
              </ContentRow>
            </Container>
            <Footer />
          </ThemeProvider>
        )
  }
};

export default Layout;

const Header = styled.header`
  font-family: "Header Font";
  text-align: center;
  font-size: 3em;
  padding-top: 20px;
  padding-bottom: 10px;
  height: 50vh;
  letter-spacing: 0.1rem;
  color: white;
  z-index: 1;
  position: relative;
  background: ${(props) => props.theme.colors.white + "ee"};

  @media (orientation: portrait) {
    height: 30vh;
    font-size: 1.8em;
  }
`;

const HeaderBackground = styled.section`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-image: url("/image/header.jpg");
  background-size: cover;
  background-position: center;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  z-index: 1;
  padding: 10px;
  border-top: ${(props) => `5px solid ${props.theme.colors.lightGray}`};
  position: relative;
  background: ${(props) => props.theme.colors.white + "ee"};
  box-shadow: 30px 16px 30px rgba(0, 0, 0, 0.03);
`;

const NavButton = styled(Link)<{selected?:boolean}>`
  font-size: 1.1rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  padding: 10px 15px;
  margin: 0 5px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark};
  font-family: "Roboto Condensed"; 
  border-bottom: ${(props) => props.selected ? "2px solid " + props.theme.colors.mainSlave  : "none"};
  ${media.mobile`
    font-size: 0.75rem;
    padding: 10px 6px;
  `}
`

const Logo = styled.img`
  height: 70px;
  margin: auto;
  margin-bottom: 10px;
  display: block;
  border-radius: 50%;
  box-shadow: 00px -10px 30px rgba(255, 255, 255, 0.1);
  ${media.mobile`
    margin-bottom: 0px;
    height: 50px;
  `}
`;



const HeaderTitle = styled.div`
  padding-right: 17px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-style: italic;
`;

const Container = styled.main`
  margin: 0 20px;
  ${media.mobile`
    margin: 0 10px;
  `}
`;

const ContentRow = styled.div`
`;

const StyledFooter = styled.footer`
  margin-top: 3rem;
  border-width: 0;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: 2rem;
  color: ${props => props.theme.colors.gray};
  text-align: center;
  span {
    font-family: "Header Font";
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    padding-left: 10px;
  }
  box-shadow: 30px -10px 30px rgba(0, 0, 0, 0.03);
`;

const BackToTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  padding-left: 7px;
  padding-top: -20px;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px ${props => props.theme.colors.black + "22"};
  z-index: 2;
  cursor: pointer;
  color: ${props => props.theme.colors.main};
`;


const Footer = () => {
  return (
    <StyledFooter>{`© 2024-${new Date().getFullYear()}`} <a href="https://inwonder.land">inwonder.land</a>
    </StyledFooter>
  );
};

export { Footer };
