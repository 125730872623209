import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Header Font';
    font-display: block;
    src: url('/font/gyahegi.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Header Font';
    font-display: block;
    src: url('/font/gyahegi.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Header Font';
    font-display: block;
    src: url('/font/gyahegi.otf') format('opentype');
  }

  @font-face {
    font-family: 'Inter';
    font-display: block;
    src: url('/font/Inter.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-display: block;
    src: url('/font/RobotoCondensed-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'fontello';
    src: url('/font/fontello.eot?1210208');
    src: url('/font/fontello.eot?1210208#iefix') format('embedded-opentype'),
         url('/font/fontello.woff?1210208') format('woff'),
         url('/font/fontello.ttf?1210208') format('truetype'),
         url('/font/fontello.svg?1210208#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  main {
    z-index: 1;
    position: relative;
  }
  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.black};
    font-size: 0.8rem;
    overflow-y: scroll;
    -webkit-tap-highlight-color: none;    
  }

  p {
    margin-bottom: 1.6rem;
  }
  
  html {
    font-family: "Inter";
    scroll-behavior: smooth;
  }
  
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.main};
    transition: color 0.3s;
  }

  a:hover {
    color: ${(props) => props.theme.colors.mainHover};; 
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: "Roboto Condensed";
    color: ${(props) => props.theme.colors.black};
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.dark};
    font-weight: 700;
    letter-spacing: 0.1rem;
  }

  h1 {
    font-size: 1.4rem;
    padding: 1.5rem 0 0.3rem;
    padding-right: 5rem;
    min-width: 250px;
    margin-bottom: 1.7rem;
    display: inline-block;
    border-bottom: ${(props) => `2px solid ${props.theme.colors.main}`};
  }

  h2 {
    font-size: 1.2rem;
    padding: 0 0 1rem;
  }

  input, textarea, select, [contenteditable] {
    height: 38px;
    padding: 6px 10px;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray};
    color: ${props => props.theme.colors.black};
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    appearance: none;
    &:focus {
      border: 1px solid ${props => props.theme.colors.main};
      outline: 0;
    }
  }

  hr {
    margin-top: 2rem;
    border: 1px solid ${props => props.theme.colors.lightgray}
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: ${props => props.theme.colors.bright};
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.gray};
    border-radius: 6px;
    border: 1px solid ${props => props.theme.colors.gray};
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${props => props.theme.colors.darkgray};
  }

  ::-moz-selection { /* Code for Firefox */
    color: ${props => props.theme.colors.black};
    background: ${props => props.theme.colors.pink};
  }

  ::selection {
    color: ${props => props.theme.colors.black  };
    background: ${props => props.theme.colors.highlight};
  }

 
  .hidden {
    display:none;
  }
  
  .icon {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: never;
      color: ${(props) => props.theme.colors.dark};
      font-size: 0.6rem;
      padding: 0 3px;
     
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      /* opacity: .8; */
     
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
     
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
     
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      margin-left: .2em;
     
      /* You can be more comfortable with increased icons size */
      /* font-size: 120%; */
     
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
     
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
  
    .leaflet-control-attribution {
      display: none !important;
    }



`;

export { GlobalStyles };
